"use client";
import axios from "axios";
import Castle from "@castleio/castle-js";
import Cookies from "js-cookie";
import { PublicConfig } from "@/shared/PublicConfig";
import { AnalyticsUtils } from "@/shared/utils/AnalyticsUtils";
import { UnderwriterConfigKey } from "@/shared/types/Quote.interface";
import { Quote } from "@/shared/types/Quote.interface";

const castle = Castle.configure({ pk: PublicConfig.CASTLE_IO_PUBLIC_API_KEY });
let clientIp: string | undefined = undefined;
const basePath = PublicConfig.BASE_PATH;

async function getClientIp(): Promise<string | undefined> {
    if (!clientIp) {
        try {
            const ipResponse = await axios.get(`${basePath}/api/info`);
            clientIp = ipResponse.data.ipAddress;
        } catch (error) {
            console.error("Error fetching client IP:", error);
        }
    }
    return clientIp;
}

async function createCastleToken(): Promise<string | undefined> {
    try {
        return await castle.createRequestToken();
    } catch (error) {
        console.error("Error creating Castle request token:", error);
        return undefined;
    }
}

axios.interceptors.request.use(
    async function (config) {
        const isQuoteApiRequest = !!config.url?.includes("quoting/quotes");
        const hasDataPayload = !!config.data && typeof config.data === "object";

        if (isQuoteApiRequest && hasDataPayload) {
            const data = config.data as Quote;
            const [token, ip] = await Promise.all([createCastleToken(), getClientIp()]);

            const existingParamsInQuote = data?.extra?.queryParams;
            const existingEmailInQuote = data?.email;
            const existingTestUserIdInQuote = data?.extra?.testUserId;

            const browserExtraData = AnalyticsUtils.constructExtra(existingParamsInQuote, existingEmailInQuote, existingTestUserIdInQuote);

            config.data.extra = {
                ...config.data.extra,
                ipAddress: ip,
                castleToken: token,
                ...browserExtraData
            };

            if (browserExtraData.segment) {
                const { userId, anonymousId } = browserExtraData.segment;
                try {
                    AnalyticsUtils.identifyUser(userId ?? anonymousId, data);
                } catch (error) {
                    console.error("Error identifying user:", error);
                }

                const underwriter = config.data?.underwriter;
                const quoteId = config.data?.quoteId;
                if (!!underwriter && !!quoteId && typeof underwriter === "string" && typeof quoteId === "string") {
                    const uwConfigKey = underwriter.replace("-", "_").toUpperCase() as UnderwriterConfigKey;
                    const cookieId = PublicConfig?.[uwConfigKey]?.COOKIE_QUOTE_ID_KEY;
                    const existingCookie = Cookies.get(cookieId);

                    if (existingCookie !== quoteId) {
                        Cookies.set(cookieId, quoteId, { expires: 365 });
                    }
                }
            }
        }

        return config;
    },
    function (error) {
        // Handle request error
        return Promise.reject(error);
    }
);
